
















































import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { throttle } from '../../../shared/utils/throttle';
const ns = namespace('editor');

@Component({
    model: {
        prop: 'visible',
        event: 'close',
    },
})
export default class Preview extends Vue {
    @Prop({ default: false }) visible!: boolean;
    @ns.State(state => state.preview.loading) previewIsLoading!: boolean;
    @ns.State(state => state.preview.files) previewFiles!: File[];
    @ns.Mutation('resetPreviews') reset!: () => void;
    @Ref('images') images!: HTMLImageElement[];
    containerWidth = 0;
    current = 0;
    fileScales: { [key: string]: { value: number; min: number; max: number } } = {};

    mounted() {
        this.onKeyUp = this.onKeyUp.bind(this);
        document.addEventListener('keydown', this.onKeyUp);
        document.addEventListener('keyup', this.onEscape);
        document.addEventListener('wheel', this.onScrollDebounce);
    }

    destroyed() {
        document.removeEventListener('keydown', this.onKeyUp);
        document.removeEventListener('keyup', this.onEscape);
        document.removeEventListener('wheel', this.onScrollDebounce);
    }

    @Watch('visible')
    onVisibilityChanged(val: boolean) {
        if (val) {
            setTimeout(() => {
                this.containerWidth = this.$el.clientWidth;
            }, 400);
        }
    }

    onScroll(e: WheelEvent) {
        if (this.currentFileName) {
            const scaleValues = this.fileScales[this.currentFileName];
            const deltaScale = (scaleValues.max - scaleValues.min) / 10;
            if (e.deltaY < 0) {
                const newValue = parseFloat((scaleValues.value + deltaScale).toFixed(1));
                scaleValues.value = newValue <= scaleValues.max ? newValue : scaleValues.max;
            } else {
                const newValue = parseFloat((scaleValues.value - deltaScale).toFixed(1));
                scaleValues.value = newValue >= scaleValues.min ? newValue : scaleValues.min;
            }
        }
    }
    onScrollDebounce = throttle(this.onScroll, 50);

    register(e: Event & { target: HTMLImageElement }, name: string) {
        const maxHeight = window.innerHeight * 0.8;
        const maxWidth = window.innerWidth * 0.7;
        const { width, height } = e.target;
        const maxScale = parseFloat(Math.min(maxWidth / width, maxHeight / height).toFixed(1));

        this.fileScales = Object.assign({}, this.fileScales, { [name]: { value: 1, min: 0.2, max: maxScale <= 1 ? 1 : maxScale } });
    }

    onKeyUp(event: KeyboardEvent) {
        if (event.which === 37 || event.code === 'ArrowLeft' || event.key === 'ArrowLeft') {
            this.onPrev();
            return;
        }
        if (event.which === 39 || event.code === 'ArrowRight' || event.code === 'ArrowRight') {
            this.onNext();
            return;
        }
    }

    onPrev() {
        if (this.current) this.current -= 1;
    }

    onNext() {
        if (this.current < this.previewFiles.length - 1) {
            this.current += 1;
        }
    }

    onEscape(event: KeyboardEvent) {
        if (event.key === 'Escape' || event.which === 27) {
            this.close();
        }
    }

    select(file: number) {
        this.current = file;
    }

    get center() {
        // return `${this.containerWidth / 2 - (this.containerWidth * 0.8) / 2 - this.containerWidth * 0.2 * this.current}px`;
        return `-${this.current * this.containerWidth}px`;
    }

    getItemWidth(index: number) {
        return (this.current === index ? this.containerWidth * 0.8 : this.containerWidth * 0.2) + 'px';
    }

    get files() {
        return this.previewFiles.map(f => ({ name: f.name, url: URL.createObjectURL(f), source: f }));
    }

    get currentFile() {
        return this.files[this.current];
    }

    get currentFileName() {
        return this.files[this.current] ? this.files[this.current].name : null;
    }

    close() {
        this.reset();
        this.current = 0;
        this.$emit('close');
    }
}
