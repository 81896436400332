
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Banner } from '@models';

const COLUMNS_STORAGE_KEY = 'editor/ColumnsPerRow';
@Component
export default class BannerGroup extends Vue {
    @Prop({ default: () => [], type: Array }) items!: Banner[];
    @Prop({ default: () => [], type: Array }) selected!: number[];
    columsPerRow = 4;

    beforeMount() {
        if (this.userId && this.columnsPerRowStorageValue) {
            try {
                this.columsPerRow = this.columnsPerRowStorageValue[this.userId];
            } catch (error) {
                console.warn(`Cannot get ${COLUMNS_STORAGE_KEY} value from localStorage`);
                console.error(error);
            }
        }
    }

    saveColumnsPerRowValue(value: number) {
        const localStorageObject = this.columnsPerRowStorageValue || {};
        if (this.userId) {
            localStorageObject[this.userId] = value;
            localStorage.setItem(COLUMNS_STORAGE_KEY, JSON.stringify(localStorageObject));
        }
    }

    columnsPerRowChanged(value: number) {
        this.columsPerRow = value;
        // this.columsPerRow = parseFloat(value);
        this.saveColumnsPerRowValue(this.columsPerRow);
    }

    get columnsPerRowStorageValue() {
        const localStorageValue = localStorage.getItem(COLUMNS_STORAGE_KEY);
        try {
            return localStorageValue ? JSON.parse(localStorageValue) : null;
        } catch (error) {
            return null;
        }
    }

    get userId() {
        return this.$auth?.claims?.UserId;
    }

    get allSelected() {
        return this.selected.length === this.items.length;
    }

    get isNewBanners() {
        return this.$route.name === 'editor.create';
    }
}
