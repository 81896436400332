import { Module } from 'vuex';
import createPaginationModule, { PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { MedialListFilterParams, BackgroundPreview } from '@/modules/media/models';
import { mediaAdapter } from '@api';
import { ListPageState } from '@/shared/models';

export interface GaleryState {
    selected: number[];

    pg?: ListPageState<MedialListFilterParams, BackgroundPreview>;
}

const galeryStore: Module<GaleryState, RootState> = {
    namespaced: true,
    state: {
        selected: [],
    },
    mutations: {
        select(state, payload: number[]) {
            state.selected = payload;
        },
        setParams(state, payload: MedialListFilterParams) {
            if (state.pg) state.pg.params = payload;
        },
    },
    actions: {
        reset({ state, commit }) {
            state.selected = [];
            commit(PAGINATION_MUTATIONS.reset);
        },
    },
    getters: {
        selected(state) {
            return state.selected;
        },
    },
    modules: {
        pg: createPaginationModule<MedialListFilterParams, BackgroundPreview>({
            api: mediaAdapter,
            initialState: () => ({
                page: {
                    size: 20,
                    index: 0,
                    rows: [],
                    totalItems: 0,
                },
                params: {
                    dimensions: [],
                    familyIds: [],
                    tags: [],
                    query: '',
                    sort: 0,
                    sortAsc: true,
                },
                dataId: 1,
            }),
            namespaced: false,
        }),
    },
};
export default galeryStore;
