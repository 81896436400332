










































import { Vue, Component, Prop } from 'vue-property-decorator';
import store from './store';
import SizeFilter from './components/SizeFilter.vue';
import EditPanel from './components/EditPanel.vue';
import Preview from './components/Preview.vue';
import BannerGroup from './components/BannerGroup.vue';
import { namespace, State } from 'vuex-class';
import { debounce } from '@/shared/utils/debounce';
import { BannerResources, Banner, BannerSaved, PageResult } from '@models';
import DialogModal, { DialogModalProps, DialogData } from '@components/Modal/DialogModal.vue';
import { GetBannersParams } from './store/actions';
import DialogService from '../../services/DialogService';
import { BackgroundPreview } from '../media/models';
import { saveImage } from '@/shared/utils';
import { BannerListFilters } from '@modules/editor/models/BannerListFilters';
import { FamiliesType } from '@shared/models/FamiliesType';

const ns = namespace('editor');

@Component({
    components: {
        BannerGroup,
        SizeFilter,
        EditPanel,
        Preview,
    },
    created() {
        this.$registerVuexModule('editor', store);
        this.$store.dispatch('editor/getResources');
    },
    beforeRouteLeave(to, from, next) {
        DialogService.showEditorDialog()
            .then(() => {
                if (!to.name || !to.name.includes('editor')) {
                    this.$store.dispatch('editor/reset');
                }
                next();
            })
            .catch(() => next(false));
    },
})
export default class Editor extends Vue {
    @Prop({ default: () => [], type: [Array, String] }) ids!: number[] | string;
    @ns.State('bgFamilies') families!: FamiliesType[];
    @ns.State('selected') selected!: (number | string)[];
    @ns.State('banners') banners!: Banner[];
    @ns.State('resources') resources!: BannerResources;
    @ns.State('pristine') pristine!: boolean;
    @ns.State('filters') filters!: BannerListFilters;
    @ns.Mutation('updateModel') updateModel!: (payload: any) => void;
    @ns.Mutation('updateBg') updateBg!: (payload: BackgroundPreview) => void;
    @ns.Mutation('resetBgSnapshots') resetBgSnapshots!: (initValue: BackgroundPreview) => void;
    @ns.Mutation('updateSelected') updateSelected!: (selected: (number | string)[]) => void;
    @ns.Mutation('undo') undoState!: () => void;
    @ns.Mutation('save') saveState!: () => void;
    @ns.Mutation('resetState') resetState!: () => void;
    @ns.Action('getBanners') getBanners!: (options: GetBannersParams) => Promise<Banner[]>;
    @ns.Action('getFilters') getFilters!: () => Promise<void>;
    @ns.Action('updateTemplates') updateTemplates!: (ids: number[]) => Promise<void>;
    @ns.Action('generate') generate!: (forPreview?: boolean) => Promise<File[]>;
    @ns.Action('saveAll') saveAll!: (name?: string) => Promise<BannerSaved[]>;
    @ns.Action('getBackgroundFamilies') getBackgroundFamilies!: () => void;
    @ns.Getter('hasUnsaved') hasUnsaved!: boolean;
    downloadDisabled = false;
    activeSize = 'All';
    saving = false;
    previewVisible = false;
    templatesIsUpdating = false;

    async mounted() {
        await this.getFilters();
        await this.getBackgroundFamilies();
        this.$store.commit('editor/setWasSaved', false);
        this.$store.commit('collapseMenu', true);
        if (this.ids) {
            const ids: number[] = typeof this.ids === 'string' ? [parseInt(this.ids)] : [...this.ids];
            if (ids.length) {
                const banners = await this.getBanners({ ids, type: this.isNewBanners ? 'templates' : 'banners' });
                this.updateSelected(banners.map(b => b.id || (b.clientSideBannerId as string)));
            }
        }
    }

    get isNewBanners() {
        return this.$route.name === 'editor.create';
    }

    get selectedBanners() {
        const selected = this.banners.filter(b => this.selected.includes(b.id) || this.selected.includes(b.clientSideBannerId as string));
        return selected;
    }

    get sizes() {
        return [...new Set(this.banners.map(this.getBannerDimension))];
    }

    //  filtered banners
    get filteredBanners() {
        return this.activeSize === 'All' ? this.banners : this.banners.filter(b => this.getBannerDimension(b) === this.activeSize);
    }

    async download() {
        try {
            this.downloadDisabled = true;
            const files = await this.generate(false);
            files.forEach(f => saveImage(f));
        } catch (error) {
            console.warn(error);
        } finally {
            this.downloadDisabled = false;
        }
    }

    async tryUpdateTemplates() {
        this.templatesIsUpdating = true;
        try {
            await this.updateTemplates(this.banners.map(b => b.template.id));
        } catch (error) {
            console.warn('Failed to update banner templates');
        } finally {
            this.templatesIsUpdating = false;
        }
    }

    updateBgPosition({ point, id }: { point: any; id: string }) {
        this.updateModel({ backgroundPosition: `${point.x}px ${point.y}px`, id: id });
    }

    getBannerDimension(b: Banner) {
        return `${b.template.width} x ${b.template.height}`;
    }

    onBgChange(bg: BackgroundPreview) {
        this.updateBg(bg);
        this.resetBgSnapshots(bg);
    }

    showPreview() {
        this.previewVisible = true;
        this.generate();
    }

    onModelChanged = debounce((changeEvent: { model: Record<string, string> }) => {
        this.updateModel(changeEvent);
    }, 50);

    back() {
        this.$router.back();
    }

    async beforeSaveAll() {
        if (this.banners[0].id) {
            await this.saveBanners();
            return;
        }
        this.$modal.show(
            DialogModal,
            {
                title: 'Save banners as',
                applyText: 'Save',
                rejectText: 'Cancel',
                input: {
                    name: 'bannerName',
                    placeholder: 'Name',
                    title: 'Type banners name',
                    validate: (value: string) => {
                        return !!value ? null : { message: 'Banners name is required!' };
                    },
                },
            } as DialogModalProps,
            {},
            {
                apply: async (data: DialogData) => {
                    if (await this.saveBanners(data.input)) {
                        // Old behaviour
                        // this.$router.push('/editor/edit?ids=' + this.banners.map(b => b.id).join('&ids='));
                        // this.updateSelected(this.banners.map(b => b.id || (b.clientSideBannerId as string)));
                    }
                },
            }
        );
    }

    async saveBanners(name?: string) {
        this.saving = true;
        try {
            await this.saveAll(name);
            this.$router.push('/banners');
            return true;
        } catch (error) {
            return false;
        } finally {
            this.saving = false;
        }
    }

    destroyed() {
        this.$store.commit('collapseMenu', false);
    }
}
