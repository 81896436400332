import { MutationTree } from 'vuex';
import { EditorState } from './index';
import { Banner } from '@/shared/models/banners/Banner';
import { BannerResources } from '@/shared/models/banners/BannerResources';
import { BackgroundPreview } from '@/modules/media/models';
import { backgroundToBackgroundPreview } from '@/shared/utils/background';
import { Template, TemplateModelField } from '@/shared/models/Template';
import { updateModelFieldByTitle } from '../utils';
import { BackgroundListFilterParams, PageResult } from '@shared/models';
import { FamiliesType } from '@shared/models/FamiliesType';

function getBannerId(banner: { id: any; clientSideBannerId: string }) {
    return banner.id === 0 || !banner.id ? (banner.clientSideBannerId as string) : banner.id;
}

const mutations: MutationTree<EditorState> = {
    setFilters(state, payload: BackgroundListFilterParams) {
        state.filters = payload;
    },
    updateSelected(state, payload: number[]) {
        state.selected = payload;
    },
    setBanners(state, payload: Banner[]) {
        state.pristine = true;
        state.banners = payload;
        state.cropperHistory = Object.assign(
            {},
            state.cropperHistory,
            payload.reduce((acc, b) => ({ ...acc, [getBannerId(b)]: [b.backgroundId] }), {})
        );
        state.backgrounds = payload.map(b => backgroundToBackgroundPreview(b.template.background)) as BackgroundPreview[];
    },
    updateBannersTemplates(state, templates: Template[]) {
        state.banners = state.banners.map(banner => {
            const newTemplate = templates.find(tmp => tmp.id === banner.template.id);
            return newTemplate ? { ...banner, template: newTemplate } : banner;
        });
    },
    setPreviews(state, payload: File[]) {
        state.preview.files = payload;
    },
    setPreviewsLoadingStatus(state, payload: boolean) {
        state.preview.loading = payload;
    },
    resetPreviews(state) {
        state.preview.loading = false;
        state.preview.files = [];
    },
    setWasSaved(state, payload = true) {
        state.wasSaved = payload;
    },
    undo(state) {
        if (state.pristine && state.history.length > 1) {
            state.history.pop();
        }
        const prevState = state.history[state.history.length - 1];
        state.banners = state.banners.map(b => {
            const existing = prevState && prevState.find(historyItem => getBannerId(historyItem) === getBannerId(b));
            if (existing) {
                return {
                    ...b,
                    ...existing,
                    model: JSON.parse(existing.model),
                };
            }
            return b;
        });
        state.pristine = true;
    },
    save(state, reset = false) {
        state.pristine = true;
        if (reset) {
            state.history = [];
        }
        state.history.push(
            state.banners.map(({ id, clientSideBannerId, backgroundImage, backgroundId, model }) => {
                return {
                    id,
                    clientSideBannerId,
                    backgroundImage,
                    backgroundId,
                    model: JSON.stringify(model),
                };
            })
        );
    },
    resetState(state) {
        state.banners = [];
        state.selected = [];
        state.history = [];
    },
    updateModel(state, payload: { id?: string; label?: string; [key: string]: any }) {
        state.pristine = false;
        if (payload.id) {
            // update certain banner
            const bannerIndex = state.banners.findIndex(banner => getBannerId(banner) === payload.id);
            if (bannerIndex !== -1) {
                delete payload.id;
                state.banners[bannerIndex].model = { ...state.banners[bannerIndex].model, ...payload };
            }
        } else {
            // update all selected
            state.banners = state.banners.map(b => {
                if (state.selected.includes(getBannerId(b))) {
                    if (payload.label) {
                        b.model = updateModelFieldByTitle(b.model, payload as TemplateModelField);
                    } else {
                        b.model = { ...b.model, ...payload };
                    }
                }
                return b;
            });
        }
    },
    updateBg(state, payload: BackgroundPreview) {
        state.pristine = false;
        Object.assign(state.backgrounds, { [payload.id]: payload });
        state.banners = state.banners.map(b => {
            if (state.selected.includes(getBannerId(b))) {
                return { ...b, backgroundImage: payload.defaultUrl, backgroundId: payload.id };
            }
            return b;
        });
    },
    setBackgrounds(state, payload: BackgroundPreview[]) {
        payload.forEach(b => {
            state.backgrounds[b.id] = b;
        });
    },
    addBgSnapshot(state, backgroundId: number) {
        state.selected.forEach(selected => {
            state.cropperHistory[selected] = (state.cropperHistory[selected] || []).concat(backgroundId);
        });
        state.cropperHistory = { ...state.cropperHistory };
    },
    undoBgSnapshot(state, backgroundId?: number) {
        state.selected.forEach(selected => {
            if (backgroundId) {
                const index = state.cropperHistory[selected].findIndex(id => id === backgroundId);
                if (index !== -1) {
                    state.cropperHistory[selected] = state.cropperHistory[selected].slice(0, index + 1);
                }
            } else {
                state.cropperHistory[selected].pop();
            }
        });
    },
    resetBgSnapshots(state, payload?: BackgroundPreview) {
        state.selected.forEach(selected => {
            state.cropperHistory[selected] = payload ? [payload.id] : state.cropperHistory[selected].slice(0, 1);
        });
    },
    setResources(state, resources: BannerResources) {
        state.resources = resources;
    },
    setFamilies(state, families: PageResult<FamiliesType>) {
        state.bgFamilies = families.rows;
    },
};
export default mutations;
