import { Module } from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import createUploaderModule, { UploadCropState } from '@/store/modules/uploadCrop';
import { Banner } from '@/shared/models/banners/Banner';
import galeryStore, { GaleryState } from './modules/galery';
import { BannerResources } from '@/shared/models/banners/BannerResources';
import { BackgroundPreview } from '@/modules/media/models';
import { BackgroundListFilterParams } from '@shared/models';
import { FamiliesType } from '@shared/models/FamiliesType';

type BannerHistoryItem = Pick<Banner, 'id' | 'clientSideBannerId' | 'backgroundImage' | 'backgroundId'> & { model: string };

export interface EditorState {
    bgFamilies: FamiliesType[];
    filters: BackgroundListFilterParams;
    pristine: boolean;
    uploader?: UploadCropState;
    banners: Banner[];
    selected: (number | string)[];
    resources: BannerResources;
    galery?: GaleryState;
    preview: {
        files: File[];
        loading: boolean;
    };
    history: BannerHistoryItem[][];
    cropperHistory: {
        [key in string | number]: number[];
    };
    backgrounds: {
        [key: number]: BackgroundPreview;
    };
    wasSaved: boolean;
}

function getInitialState(): Omit<EditorState, 'wasSaved'> {
    return {
        bgFamilies: [],
        filters: {
            dimensions: [],
            sort: 0,
            query: '',
            sortAsc: false,
        },
        pristine: true,
        banners: [],
        selected: [],
        resources: { icons: [], fonts: [] },
        preview: {
            files: [],
            loading: false,
        },
        history: [],
        cropperHistory: {},
        backgrounds: {},
    };
}
const module: Module<EditorState, RootState> = {
    namespaced: true,
    state: { ...getInitialState(), wasSaved: false },
    getters,
    actions: {
        ...actions,
        reset({ state, commit, dispatch }) {
            Object.assign(state, getInitialState());
            dispatch('galery/reset');
            commit('uploader/reset');
        },
    },
    mutations,
    modules: {
        galery: galeryStore,
        uploader: createUploaderModule(2),
    },
};
export default module;
