













import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component
export default class SizeFilter extends Vue {
    @Prop({ default: () => ['300 x 250', '250 x 250', '300 x 600'] }) sizes!: string[];
    @Model('change') value!: string;

    get allSizes() {
        return ['All'].concat(this.sizes);
    }
}
