import { GetterTree } from 'vuex';
import { EditorState } from '.';

const getters: GetterTree<EditorState, RootState> = {
    selectedBanners(state) {
        return state.banners.filter(b => state.selected.includes(b.id) || state.selected.includes(b.clientSideBannerId as any));
    },
    hasUnsaved(state) {
        return !state.pristine || state.history.length > 1;
    },
    getBackgroundUndoIds(state) {
        return state.selected
            .map(selected => {
                const bgHistory = state.cropperHistory[selected];
                return bgHistory ? bgHistory[bgHistory.length - 1] : null;
            })
            .filter(Boolean);
    },
    getBackgrouds(state) {
        return (ids: number[]) => ids.map(id => state.backgrounds[id]).filter(bg => !!bg);
    },
};

export default getters;
