import { ActionTree } from 'vuex';
import { EditorState } from '.';
import { bannersAdapter, mediaAdapter, templatesAdapter } from '@api';
import { Banner, BannerSave, templateToBanner } from '@/shared/models/banners/Banner';
import { prepareBannerDataModel, setLastModified } from '@/shared/utils';
import templateAdapter from '@adminServices/TemplateAdminApiAdapter';
import familyAdapter from '@services/api/adapters/FamilyAdapter';
import { FamilyType, Sorting } from '@models';

export interface GetBannersParams {
    ids: number[];
    type: 'templates' | 'banners';
}

const actions: ActionTree<EditorState, RootState> = {
    async getBanners({ commit }, options: GetBannersParams): Promise<Banner[]> {
        let banners: Banner[];
        if (options.type === 'banners') {
            banners = await bannersAdapter.getBannersWithScheme(options.ids);
        } else {
            const templates = await templatesAdapter.getTemplatesWithScheme(options.ids);
            banners = templates.map(t => templateToBanner(t));
        }
        commit('setBanners', banners);
        commit('save');
        return banners;
    },
    async updateTemplates({ commit }, ids: number[]) {
        commit('updateBannersTemplates', await templatesAdapter.getTemplatesWithScheme(ids));
    },
    async saveAll({ commit, state }, name?: string) {
        const { data } = await bannersAdapter.save(
            state.banners.map(b => {
                return {
                    bannerId: b.id,
                    clientSideBannerId: b.clientSideBannerId,
                    modelJson: JSON.stringify(prepareBannerDataModel(b)),
                    name: name ? `${name}-${b.template.name}${b.template.width}x${b.template.height}` : b.name,
                    templateId: b.template?.id,
                    backgroundId: b.backgroundId || b.template.background.id,
                    generatedImageId: b.generatedImageId,
                } as BannerSave;
            })
        );
        data.banners.forEach((b, i) => {
            state.banners[i].id = b.bannerId;
            state.banners[i].name = b.name;
        });
        commit('setBanners', state.banners);
        commit('setWasSaved', true);
        commit('save', true);
        setLastModified(
            data.banners.map(b => b.bannerId),
            state.banners[0].template.family.id
        );
        return data.banners;
    },
    async generate({ commit, state, getters }, forPreview = true) {
        const selected = getters.selectedBanners as Banner[];
        forPreview && commit('setPreviewsLoadingStatus', true);
        const generatedImages = await Promise.all(
            selected.map(async banner =>
                bannersAdapter
                    .generate({
                        bannerId: banner.id,
                        modelJson: JSON.stringify(prepareBannerDataModel(banner)),
                        clientSideBannerId: banner.clientSideBannerId,
                        name: banner.name,
                        templateId: banner.template.id,
                        backgroundId: banner.backgroundId,
                    })
                    .catch(err => {
                        return null;
                    })
            )
        );
        forPreview && commit('setPreviewsLoadingStatus', false);
        const files = generatedImages
            .map((res, i) => {
                const template = selected[i].template;
                const fileName = `${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}_${template.name}_${template.width}${
                    selected[i].template.height
                }.png`;
                return res ? new File([res.data], fileName, { type: res.headers['content-type'] }) : null;
            })
            .filter(Boolean);
        forPreview && commit('setPreviews', files);
        return files;
    },
    async getResources({ commit, state }) {
        commit('setResources', await bannersAdapter.getBannerEditingResources());
    },
    async upload({ commit, state, getters, dispatch, rootState }) {
        const file: Blob = getters['uploader/stageFile'];
        if (file) {
            const { backgrounds } = await mediaAdapter.upload({ file, name: state.uploader?.file?.name, isTemp: true });
            commit('updateBg', backgrounds[0]);
            commit('addBgSnapshot', backgrounds[0].id);
        }
    },
    async getFilters({ commit }) {
        commit('setFilters', await templateAdapter.getFilters());
    },
    undoBackground({ state, commit, getters }) {
        commit('undoBgSnapshot');
        const historyIds = getters['getBackgroundUndoIds'];
        if (new Set(historyIds).size === 1) {
            commit('updateBg', state.backgrounds[historyIds[0]]);
        }
    },
    async getBackgroundFamilies({ state, commit }) {
        commit('setFamilies', await familyAdapter.getPage({ size: 100, params: { query: '', options: FamilyType.BackgroundFamily } }));
    },
};
export default actions;
