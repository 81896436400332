import { render, staticRenderFns } from "./BannerGroup.vue?vue&type=template&id=e6bdaa36&scoped=true&"
import script from "./BannerGroup.vue?vue&type=script&lang=ts&"
export * from "./BannerGroup.vue?vue&type=script&lang=ts&"
import style0 from "@styles/components/banners-group.css?vue&type=style&index=0&id=e6bdaa36&scoped=true&lang=css&"
import style1 from "./BannerGroup.vue?vue&type=style&index=1&id=e6bdaa36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6bdaa36",
  null
  
)

export default component.exports